import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function PlanCard({ planCode, planDescription, images, onSelectPlan }) {
  const navigate = useNavigate();

  const handleSelectPlan = () => {
    navigate('/prepayment', { state: { selectedPlan: { planCode, planDescription } } });
  };

  return (
    <div className="shadow-md rounded-lg flex flex-col border border-white overflow-hidden">
      <div
        className="w-full h-12 bg-gradient-to-r rounded-t-lg text-white p-3 text-left font-bold"
        style={{
          backgroundImage: `linear-gradient(to right, ${planDescription.gradientFrom}, ${planDescription.gradientTo})`,
        }}
      >
        {planDescription.name}
      </div>
      <div className="bg-white text-left px-4 py-2">
        <span className="text-[#161021] text-bold font-[Noto Sans]">
          {planDescription.subHeading}
        </span>
      </div>
      {/* Icon grid container */}
      <div className=" bg-white grid grid-cols-4 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-2 p-2">
    {images.map((icon, index) => (
      <div key={index} className="flex justify-center items-center h-24">  {/* Container to control the height and centering */}
      <img
          src={`/${icon}`} // Assuming icons are in the public directory
          alt="Service Icon"
          className="max-w-full max-h-full" // Ensures the image stays within the bounds of the container
      />
  </div>
    ))}
</div>

      <div className="bg-white text-left px-4 py-2">
        <span className="text-gray-500 line-through">
          {planDescription.originalPrice}
        </span>
        <span className="text-black">
          {' '}
          {planDescription.price} / महीना
        </span>
      </div>
      <div className="bg-white flex flex-row justify-between p-4">
        <button
          onClick={handleSelectPlan}
          className="w-full text-white bg-pink-500 py-2 px-4 rounded hover:bg-pink-600 transition duration-300"
        >
          प्लान चुनें
        </button>
      </div>
    </div>
  );
}

// Main PlanSelection Component
export default function PlanSelection({ plans, plansImages, onSelectPlan }) {
  return (
    <div className="grid grid-cols-1 gap-4 w-full max-w-md">
      {Object.entries(plans).map(([planCode, planDescription], index) => (
        <PlanCard
          key={planCode}
          planCode={planCode}
          planDescription={planDescription}
          images={plansImages[index]} // Assuming each plan has an array of images
          onSelectPlan={onSelectPlan}
        />
      ))}
    </div>
  );
}
