import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Navbar from "../Components/Navbar";
import Lottie from "react-lottie";
import { logData } from "../functions/logging";

export default function SuccessTransaction() {
  const location = useLocation();
  const navigate = useNavigate();
  const [animationData, setAnimationData] = useState(null);
  const [progressWidth, setProgressWidth] = useState("0%");
  const [showNextStepText, setShowNextStepText] = useState(true);
  const [loading, setLoading] = useState(true);
  const [isPaymentSuccessful, setIsPaymentSuccessful] = useState(false);
  const [username, setUsername] = useState("");
  const [planCode, setPlanCode] = useState("");
  const [mobile, setMobile] = useState("");
  const [planAmount, setPlanAmount] = useState("");
  const loadAnimationData = async () => {
    const response = await fetch("/payment_success.json");
    const data = await response.json();
    setAnimationData(data);
  };
  const searchParams = new URLSearchParams(location.search);
  const transactionId = searchParams.get("order_id");

  useEffect(() => {
    const checkPaymentStatus = async () => {
      if (!transactionId) return;
      const url = `https://payment.stage.i2e1.in/api/WiomApi/checkPaymentStatus?transactionId=${transactionId}`;
      try {
        const response = await fetch(url, {
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();

        if (data.status === 0 && data.data.status === "success") {
          setIsPaymentSuccessful(true);
          setUsername(data.data.paymentData.extraData.name);
          setPlanCode(data.data.paymentData.extraData.planCode);
          setMobile(data.data.paymentData.extraData.phoneNumber);

          await logData({
            topic: "pmwani_logs",
            name: "ott_transaction_success",
            key: "ott",
            mobile: data.data.paymentData.extraData.phoneNumber,
            feature: "payment",
            data: {
              transactionId: transactionId,
              status: "success",
              planCode: data.data.paymentData.extraData.planCode,
              username: data.data.paymentData.extraData.name,
              mobile: data.data.paymentData.extraData.phoneNumber,
            },
          });

          await activateSubscription(data.data.paymentData.extraData);
        } else {
          await logData({
            topic: "pmwani_logs",
            name: "ott_transaction_failure",
            key: "ott",
            mobile: data.data.paymentData.extraData.phoneNumber,
            feature: "ott_payment",
            data: {
              transactionId: transactionId,
              status: "failure",
              planCode: data.data.paymentData.extraData.planCode,
              username: data.data.paymentData.extraData.name,
              mobile: data.data.paymentData.extraData.phoneNumber,
            },
          });
          navigate("/failure");
        }
      } catch (error) {
        console.error("Error checking payment status:", error);
        // alert('An error occurred while checking the payment status.');
        await logData({
          topic: "pmwani_logs",
          name: "transaction_error_in_juspay",
          key: "ott",
          mobile: transactionId || "transaction ID is not available ", // or mobile if available
          feature: "ott_payment",
          data: {
            transactionId: transactionId,
            status: "error",
            error: error.message,
            planCode:  "planCode not available",
            username:"name not available",
            mobile:  "phone number not available",
          },
        });
        navigate("/failure");
      } finally {
        setLoading(false);
      }
    };

    checkPaymentStatus();
  }, [transactionId, navigate]);

  useEffect(() => {
    if (isPaymentSuccessful) {
      const loadAnimationData = async () => {
        try {
          const response = await fetch("/payment_success.json");
          if (!response.ok) throw new Error("Failed to load animation data");
          const data = await response.json();
          setAnimationData(data);
          setProgressWidth("100%");
          setShowNextStepText(false);
        } catch (error) {
          console.error("Error loading animation:", error);
        }
      };

      loadAnimationData();
    }
  }, [isPaymentSuccessful]);

  const activateSubscription = async (extraData) => {
    const url = "https://labs-api.wiom.in/subscribe_ott";
    const requestData = {
      phone: extraData.phoneNumber,
      first_name: extraData.name,
      plan_code: extraData.planCode,
      mode: "CREATE_ACTIVATE",
    };

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });

      const data = await response.json();
      if (data.data && data.data.success) {
        // alert("Subscription activated successfully!");
        await logData({
          topic: 'pmwani_logs',
          name: 'ottplay_activation_success',
          key: 'ott',
          mobile: extraData.phoneNumber,
          feature: 'ottplay_subscription',
          data: {
            transactionId: extraData.transactionId, // Assuming transactionId is available
            status: 'success',
            planCode: extraData.planCode,
            username: extraData.name,
            mobile: extraData.phoneNumber,
            api_mode: "CREATE_ACTIVATE",
          },
        });
        if (isPaymentSuccessful) {
          loadAnimationData();
          setProgressWidth("100%");
          setShowNextStepText(false);
        }
      } else {
        // alert(
        //   `Failed to activate subscription: ${data.data.msg || "Unknown error"}`
        // );
        await logData({
          topic: 'pmwani_logs',
          name: 'ottplay_activation_failure',
          key: 'ott',
          mobile: extraData.phoneNumber,
          feature: 'ottplay_subscription',
          data: {
            transactionId: extraData.transactionId, // Assuming transactionId is available
            status: 'failure',
            error: data.data.msg,
            planCode: extraData.planCode,
            username: extraData.name,
            mobile: extraData.phoneNumber,
          },
        });
        navigate("/failure");
      }
    } catch (error) {
      console.error("Error activating subscription:", error);
      // alert(
      //   "An error occurred while activating the subscription. Please check the console for more details."
      // );
      await logData({
        topic: 'pmwani_logs',
        name: 'ottplay_activation_error',
        key: 'ott',
        mobile: extraData.phoneNumber,
        feature: 'ottplay_subscription',
        data: {
          transactionId: extraData.transactionId, // Assuming transactionId is available
          status: 'error',
          error: error.message,
          planCode: extraData.planCode,
          username: extraData.name,
          mobile: extraData.phoneNumber,
        },
      });
      navigate("/failure");
    }
  };

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
    eventListeners: [
      {
        eventName: "complete",
        callback: () => navigate(`/download-app?mobile${mobile}`),
      },
    ],
  };

  if (loading) {
    return (
      <div>
        <Navbar wiomVisible={true} logoSrc={"wiom-icon.svg"} />
        <div className="flex justify-center items-center h-screen">
          <div>Loading, please wait...</div>
        </div>
      </div>
    );
  }

  if (!isPaymentSuccessful) {
    return null;
  }
  const handleAnimationComplete = () => {
    navigate(`/download-app?mobile=${mobile}`);
  };

  return (
    <div>
      <Navbar wiomVisible={true} logoSrc={"wiom-icon.svg"} />
      <div className="flex justify-center items-center pb-32 h-screen">
        {animationData ? (
          <div className="flex flex-col items-center">
            <Lottie
              options={defaultOptions}
              height={100}
              width={100}
              eventListeners={[
                {
                  eventName: "complete",
                  callback: handleAnimationComplete,
                },
              ]}
            />
            <div className="w-screen text-center text-[#161021] text-2xl font-bold font-['Noto Sans'] leading-loose">
              ₹{planAmount} भुगतान सफल हुआ
            </div>
            <div className="w-full flex flex-col items-center mt-4">
              <div className="w-3/4 h-4 bg-[#e1faed] rounded-full">
                <div
                  className="h-2 rounded-full"
                  style={{
                    backgroundColor: "#38a169",
                    width: progressWidth,
                    transition: "width 0.5s ease-in-out",
                  }}
                ></div>
              </div>
              {showNextStepText && (
                <div className="w-[328px] text-center text-[#161021] text-sm font-normal font-['Noto Sans'] leading-tight mt-2">
                  अगले स्टेप पर ले जाया जा रहा है
                </div>
              )}
            </div>
          </div>
        ) : (
          <div>Loading animation...</div>
        )}
      </div>
      <div className="text-center text-2xl font-bold mt-4">
        Payment Successful!
      </div>
    </div>
  );
}
