import React, { useEffect,useState } from 'react';
import { useNavigate } from 'react-router-dom';

function StartingPage() {
  const navigate = useNavigate();

  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const interval = 50; // Interval in milliseconds for the timer update
    const totalDuration = 5000; // Total duration in milliseconds for the loader
    const timer = setTimeout(() => navigate('/main'), totalDuration);
    const progressUpdate = setInterval(() => {
      setProgress((oldProgress) => {
        const newProgress = oldProgress + (interval / (totalDuration)) * 100;
        if (newProgress >= 100) clearInterval(progressUpdate);
        return newProgress;
      });
    }, interval);

    return () => {
      clearTimeout(timer);
      clearInterval(progressUpdate);
    };
  }, [navigate]);

  return (
    <div className="relative w-full h-screen bg-cover bg-bottom" style={{ backgroundImage: 'url("/starting-bg.png")' }}>
      {/* Gradient Overlay */}
      <div className="absolute inset-0 bg-black-gradient flex flex-col justify-between">
        <div>
          <div className="Navbar-startingPage">
            <div className="w-full h-full flex items-center gap-1">
              <div className="h-12 p-3 flex justify-center items-center gap-4">
                <div className="relative w-8 h-8 bg-[#D9008D] rounded-lg overflow-hidden">
                  <img className="absolute w-full h-full" src="/wiom-icon.svg" alt="Logo" />
                </div>
              </div>
              <div className="text-[#D9008D] text-lg font-bold leading-7 break-words">
                Wiom
              </div>
            </div>
            <div className='h-sc'>
                <div className=" pt-32 w-full text-center text-[#FAF9FC] text-4xl font-bold leading-[48px] break-words font-sans">
                सपनों से ज़्यादा मनोरंजन 
                </div>
                <div className=" pt-5 w-full text-center">
                <span className="text-[#FAF9FC] text-base font-bold font-sans leading-normal">देखें शानदार OTT फिल्में और शो</span><br/>
                <span className="text-[#D9008D] text-2xl font-bold font-sans leading-normal">सिर्फ ₹10 में </span>
                </div>
                </div>
          </div>
        </div>
        <div className="absolute bottom-0 left-0 h-2 bg-[#ffffff] transition-all duration-500" style={{ width: `${progress}%` }}></div>

      </div>
    </div>
  );
}

export default StartingPage;
