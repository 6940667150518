import React from "react";

import Navbar from "../Components/Navbar";
import { isIOS, isAndroid } from 'react-device-detect';

export default function DownloadApp() {
  const handleDownloadClick = () => {
    let appStoreUrl = "https://www.ottplay.com/";
  
    if (isIOS) {
      appStoreUrl = "https://apps.apple.com/in/app/ottplay/id1536115085"; // Replace with your actual iOS app store link
    } else if (isAndroid) {
      appStoreUrl = "https://play.google.com/store/apps/details?id=com.ht.ottplay&hl=en_IN";
    }
  
    if (appStoreUrl) {
      window.open(appStoreUrl, "_blank");
    } 
  };
  
  
  const usePhoneNumberFromUrl=()=> {
    const searchParams = new URLSearchParams(window.location.search);
    const mobile = searchParams.get('mobile');
    return mobile;
  }
  const phoneNumber = usePhoneNumberFromUrl();

  return (
    <>
      <div className="flex flex-col min-h-screen bg-[#faf9fc]">
        <Navbar wiomVisible={true} logoSrc={"wiom-icon.svg"} />

        <div className="flex-grow overflow-auto">
          <div className="px-6 py-4">
            <div className="text-left text-2xl">
              सिर्फ 3 स्टेप में <br />
              <div className="font-bold">अपना मनोरंजन शुरू करें</div>
            </div>
          </div>
          <div className="flex flex-col items-center px-6 space-y-4">
            <div className="bg-white w-full flex flex-row p-4 rounded-lg  space-x-4">
              <div className="flex flex-col text-left flex-grow">
                <div className="font-bold font-['Noto Sans'] leading-7">
                  स्टेप 1:
                </div>
                <div>OTT Play ऐप डाउनलोड करें</div>
              </div>
              <div className="flex-shrink-0 ml-4 w-1/2 h-30 md:w-32 md:h-32">
                <img
                  src="stepOne.png"
                  alt="Step 1"
                  className="rounded object-cover w-full h-full"
                />
              </div>
            </div>
            <div className="bg-white w-full flex flex-row p-4 rounded-lg  space-x-4">
              <div className="flex flex-col text-left flex-grow">
                <div className="font-bold font-['Noto Sans'] leading-7">
                  स्टेप 2:
                </div>
                <div>{phoneNumber}<br/> से OTT Play <br/>ऐप पर लॉग इन करें</div>
              </div>
              <div className="flex-shrink-0 ml-4 w-1/2 h-30 md:w-32 md:h-32">
                <img
                  src="stepTwo.png"
                  alt="Step 2"
                  className="rounded object-cover w-full h-full"
                />
              </div>
            </div>
            <div className="bg-white w-full flex flex-row p-4 rounded-lg  space-x-4">
              <div className="flex flex-col text-left flex-grow">
                <div className="font-bold font-['Noto Sans'] leading-7">
                  स्टेप 3:
                </div>
                <div>OTT का मज़ा लें</div>
              </div>
              <div className="flex-shrink-0 ml-4 w-1/2 h-30 md:w-32 md:h-32">
                <img
                  src="stepThree.png"
                  alt="Step 3"
                  className="rounded object-cover w-full h-full"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="sticky bottom-0 left-0 right-0 flex justify-center py-4 bg-[#faf9fc]">
          <button
            className="w-full max-w-screen-md mx-4 px-4 py-3 bg-[#d9008d] rounded-2xl text-center text-[#faf9fc] text-base font-bold font-['Noto Sans'] leading-normal cursor-pointer"
            onClick={handleDownloadClick}
          >
            अभी डाउनलोड करें
          </button>
        </div>
      </div>
    </>
  );
}
