// src/utils/logData.js

export async function logData({ topic, name, key, mobile, feature, data }) {
    const url = `https://services.i2e1.in/Home/Note?topic=${topic}&name=${name}&key=${key}&mobile=${mobile}&feature=${feature}`;
  
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Accept': '*/*',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ data }),
      });
  
      if (!response.ok) {
        console.error('Failed to log data:', response.statusText);
      } else {
        console.log('Data logged successfully');
      }
    } catch (error) {
      console.error('Error logging data:', error);
    }
  }
  