import { useEffect, useState } from 'react';
import PlanSelection from '../Components/PlanSelection';
import Navbar from '../Components/Navbar';
import Modal from '../Components/Modal';
import PaymentModal from '../Components/PaymentModal';
export default function HomeMainPage() {
  const [name, setName] = useState(''); 
  const [mobileNumber, setMobileNumber] = useState('');
  const [loading, setLoading] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState('');
  const [userIsSubscribed, setUserIsSubscribed] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');
  const [view, setView] = useState('selectPlan');
  const [showModal, setShowModal] = useState(false);
  const [apiResponse, setApiResponse] = useState({});
  const [isActivePlan, setIsActivePlan] = useState(false);
  const [subscriberDetails, setSubscriberDetails] = useState({});
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [selectedPlanDetails, setSelectedPlanDetails] = useState({});

  const plans = {
    "ott_monthly_wiom_mini": { name: "व्योम मिनी ",EnglisName: "Wiom Mini Monthly Pack", price: "INR 10" ,code:"ott_monthly_wiom_mini" ,originalPrice:"Rs. 149" ,gradientFrom: "#161021",gradientTo: "#C72323",subHeading:"8 OTT का छोटा पैक, बड़ा एंटरटेनमेंट",ott:'8'},

    "ott_monthly_wiom_nasha": { name: "व्योम नशा ",EnglisName: "Wiom Nasha Monthly Pack", price: "INR 20" ,code :"ott_monthly_wiom_nasha",originalPrice:"Rs. 249" ,gradientFrom: "#161021",gradientTo: "#546CC1",subHeading:"11 OTT द्वारा धुआंधार मज़ा",ott:'11'},
    "ott_monthly_wiom_jhakas": { name: "व्योम झक्कास ",EnglisName: "Wiom Jhakas Monthly Pack", price: "INR 99",code : "ott_monthly_wiom_jhakas",originalPrice:"Rs. 299" ,gradientFrom: "#161021",gradientTo: "#AD6B29" ,subHeading: "29 OTT द्वारा अनलिमिटेड एंटरटेनमेंट",ott:'29'},

  };

  const plansImages = [
    ["mini/alt.png","mini/DistroTV.png","mini/itapblack.png","mini/omtv.png","mini/powerkids.png","mini/primeflix.png","mini/sansakar.jpg","mini/shemarooMe.png"], // Images for Wiom Jhakas Monthly Pack
    ["nahsa/alt.png","nahsa/DistroTV.png","nahsa/fancode.png","nahsa/microsoftteams.png","nahsa/powerkids.png","nahsa/omtv1.png","nahsa/sansakar.jpg","nahsa/ptcplay.png","nahsa/runntv.png",'nahsa/sonyliv.png',"nahsa/ullu.png"], // Images for Wiom Jhakas Monthly Pack
    [ "jhakaas/aaonxticon-958.png",
      "jhakaas/altticon2-607.png",
      "jhakaas/bhaktiflix1x1-1710136999.png",
      "jhakaas/Chaupal_Punjabi_provider_poster_1_141.png",
      "jhakaas/cinemaworld1x1-1701943373.png",
      "jhakaas/dangal-play1x1-1703773323.png",
      "jhakaas/DistroTV-logo (1).png",
      "jhakaas/docubay.png",
      "jhakaas/dol-logo-165.png",
      "jhakaas/fan-61.png",
      "jhakaas/icon-833-1689834498.png",
      "jhakaas/istream-832.png",
      "jhakaas/itapblack1x1-297.png",
      "jhakaas/jhakaas.png",
      "jhakaas/kanccha-lannka-icon-210.png",
      "jhakaas/microsoftteams-image-1706532855.png",
      "jhakaas/omtv1x1-1703674855.png",
      "jhakaas/PlayFlix_provider_poster_1_55.png",
      "jhakaas/powerkids1x1-1-1709209457.png",
      "jhakaas/primeflix-11-1709729465.png",
      "jhakaas/ptc-play (1).png",
      "jhakaas/raj-609.png",
      "jhakaas/runntv1x1-1702897676.png",
      "jhakaas/sansakar.jpg",
      "jhakaas/shemarooMe.png",
      "jhakaas/sonyliv.png",
      "jhakaas/stageicon-154.png",
      "jhakaas/ullu1x1-1702988281.png",
      "jhakaas/vrott1x1-1702988328.png",
      "jhakaas/zee5.png"], // Images for Wiom Jhakas Monthly Pack
  ];




  const handlePlanSelection = (planCode) => {
    console.log('Selected plan:', planCode);
    setSelectedPlan(planCode);
    setShowModal(true); // Show modal after plan selection
  };

  const handleNameChange = (newName) => {
    setName(newName);
  };

  const handlePhoneNumberSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    const url = 'http://labs-api.wiom.in/get_status_ott';
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ phone: mobileNumber })
      });

      const data = await response.json();
      setLoading(false);

      if (response.ok && data.data.success) {
        if (data.data.subscriber_details?.active_plan) {
          setSubscriberDetails(data.data.subscriber_details);
          setIsActivePlan(true);
          setUserIsSubscribed(true);
          setResponseMessage("Plan is active, enjoy the latest content on OTTPlay app.");

          // Close the modal after 5 seconds
          setTimeout(() => {
            setShowModal(false);
            window.location.reload(); // This will refresh the page

          }, 5000);
        } else {
          setUserIsSubscribed(false);
          setIsActivePlan(false);
          console.log('User is trying to subscribe to:', selectedPlanDetails);
        }
      } else {
        setUserIsSubscribed(false);
        setIsActivePlan(false);
        setResponseMessage(data.data.msg || "Please complete your registration.");
        setView('selectPlan');
      }
    } catch (error) {
      console.error('Error checking subscription status:', error);
      setLoading(false);
      setResponseMessage('Failed to check subscription status. Please try again.');
      setView('selectPlan');
    }
  };

  const handlePhoneNumberChange = (event) => {
    setMobileNumber(event.target.value);
  };

  const handleSignOut = () => {
    setMobileNumber('');
    setUserIsSubscribed(false);
    setSelectedPlan('');
    setSubscriberDetails({});
    setApiResponse({});
    setIsActivePlan(false);
    setShowModal(false);
    setView('selectPlan');
    setName('');
  };

  const closeModal = () => {
    setShowModal(false);
    window.location.reload(); // This will refresh the page

  };

  return (
    
    <div className="bg-black-500 min-h-screen flex flex-col bg-[#161021]">
      <div>
      <Navbar backgroundColor={'#161021'} logoSrc={'/wiom-icon.svg'} routeToNavigate={'/main'} wiomVisible={true}/>
      </div>
      <div className='w-screen' >
      <div className=" text-[#faf9fc] p-6 text-left text-2xl font-bold font-['Noto Sans']">अपना प्लान चुनें और अभी स्ट्रीमिंग शुरू करें</div>
      </div>


      <div className='p-6'>
      <PlanSelection plans={plans} plansImages={plansImages} onSelectPlan={handlePlanSelection} />
      </div>
    </div>
  );
}
